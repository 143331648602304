<template>
<div>
    <div class="display-block">
        <v-alert v-if="msg" :type="msgType">{{ msg }}</v-alert>
        <page-header v-model="search" title="Product" @input="updateApiCall(apiCall,search)" permission="createProduct" @click="navigateTo('/app/products/0')" @bundleClick="navigateTo('/app/products/productBundle/0')"></page-header>
        <v-flex text-right>
            <import-file-button importData='products'></import-file-button>
        </v-flex>
    </div>
    <div>
        <v-data-table :loading="loading" :headers="headers" :page="currentPage" 
        @update:page="setCurrentPage($event,lsCurrentPageKey)" :items="items" class="elevation-1" :search="search"
        hide-default-footer :items-per-page="items.length">
            <template v-slot:item.images="{item}" v-if="$vuetify.breakpoint.smAndUp">
                <video-player v-if="item.images[0]&&item.images[0].mimetype.startsWith('video/')" :src="item.images[0].path" height="64" width="64"></video-player>
                <v-img v-else-if="item.images[0]&&item.images[0].mimetype.startsWith('image/')" :src="imagePath(item.images[0].path)" height="64px" width="64px"></v-img>
            </template>
            <template v-slot:item.brand="{item}" v-if="$vuetify.breakpoint.smAndUp">
                <label v-if="item.brand">{{item.brand.displayName}}</label>
            </template>
             <template v-slot:item.active="{item}" v-if="$vuetify.breakpoint.smAndUp">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-if="item.active" v-bind="attrs" v-on="on" class="active-icon">mdi-check-circle-outline</v-icon>
                    </template>
                    <span>Enabled</span>
                </v-tooltip>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-if="!item.active" v-bind="attrs" v-on="on" class="deactive-icon">mdi-cancel</v-icon>
                    </template>
                    <span>Disabled</span>
                </v-tooltip>
            </template>
            <template v-slot:item.bestDeal="{item}" v-if="$vuetify.breakpoint.smAndUp">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-if="item.bestDeal" v-bind="attrs" v-on="on" class="active-icon">mdi-check-circle-outline</v-icon>
                    </template>
                    <span>Enabled</span>
                </v-tooltip>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-if="!item.bestDeal" v-bind="attrs" v-on="on" class="deactive-icon">mdi-cancel</v-icon>
                    </template>
                    <span>Disabled</span>
                </v-tooltip>
            </template>
            <template v-slot:item.type="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                <v-chip small>{{item.type}}</v-chip>
            </template>
            <template v-slot:item.action="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                <template v-for="icon in icons">
                    <action-button :key="icon.name" :icon="icon.name" :show="isAllowed(icon.permission)" @click="checkIcons(icon,item)"></action-button>
                </template>
            </template>
            <template v-slot:item="{item}" v-if="$vuetify.breakpoint.xsOnly">
                <product-card :product="item" :icons="icons" @action="checkIcons"></product-card>
            </template>
        </v-data-table>
        <pagination ref="pagination" :api="apiCall" v-model="items" sort="+code" :update="search!=''"></pagination>
    </div>
    <v-alert v-if="msg" :type="msgType">{{msg}}</v-alert>
</div>
</template>

<script>
import ActionButton from '@/components/ActionButton'
import appConstants from '@/utils/appConstants'
import ProductCard from '@/components/ProductResponsiveCard'
import ImportFileButton from '@/components/ImportFileButton'
export default {
    components: {
        ActionButton,
        ProductCard,
        ImportFileButton
    },
    data() {
        return {
            search: '',
            headers: [{
                    text: 'Image',
                    value: 'images'
                }, {
                    text: 'Code',
                    value: 'code'
                },
                {
                    text: 'Title',
                    value: 'title'
                },
                {
                    text: 'Brand',
                    value: 'brand.displayName'
                },
                {
                    text: 'Price',
                    value: 'price',
                    align: 'right'
                },
                {
                    text: 'Offer Price',
                    value: 'offerprice',
                    align: 'right'
                },
                {
                    text:"Active",
                    value:'active'
                },
                {
                    text: 'Best Deal',
                    value: 'bestDeal',
                },
                {
                    text:'Display Order',
                    value:'displayOrder',
                    align:'right'
                },
                {
                    text:'Type',
                    value:'type'
                },
                {
                    text: 'Action',
                    value: 'action',
                    align: 'right'
                }
            ],
            search: '',
            items: [],
            brands: [],
            icons: [{
                    name: "mdi-eye",
                    path: "/app/products/view/",
                    permission: "viewProduct"
                }, {
                    name: "mdi-pencil",
                    path: "/app/products/",
                    permission: "editProduct"
                },
                {
                    name: "mdi-delete",
                    permission: "deleteProduct"
                }
            ],
            lsCurrentPageKey:"app-products-current-page",
            loading: false,
            apiCall:appConstants.PRODUCTS_API
        }
    },
    methods: {
        async deleteProduct(id) {
            try {
                await this.deleteItem("Are you sure you want to delete this Product?", appConstants.PRODUCTS_API + "/" + id)
                this.items.splice(this.items.findIndex(rec => rec._id == id), 1)
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data.details
            }
        },
        removeItem(id) {
            this.deleteProduct(id)
        },
        checkIcons(icon,item){
            if(icon.name=='mdi-delete')
                this.deleteProduct(item._id)
            else if(item.type=='BUNDLE')
                this.$router.push(icon.path+'productBundle/'+item._id)
            else
                this.$router.push(icon.path+''+item._id)
        }
    }
}
</script>

<style scoped>
.circle {
    border-radius: 3px;
}
</style>
